import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  output,
  signal,
  ViewChild,
} from '@angular/core';

import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';

@Component({
  selector: 'cc-dropdown-action',
  standalone: true,
  imports: [OverlayModule],
  templateUrl: './dropdown-action.component.html',
  styleUrls: ['./dropdown-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownActionComponent {
  @Input()
  leadingIcon: string | null = null;

  @Input()
  leadingIconColor: string | null = null;

  @Input()
  trailingIcon: string | null = null;

  @Input()
  selectable = false;

  @Input()
  selected = false;

  @Input()
  active = false;

  @Input()
  disabled = false;

  @Input('focusable')
  _focusable = true;

  requestFocus = output<HTMLElement>();

  @ContentChild(DropdownComponent)
  childDropdown: DropdownComponent | null = null;

  @ViewChild('dropdownTrigger', { static: true, read: ElementRef })
  dropdownTrigger: ElementRef<HTMLDivElement> | null = null;

  childDropdownVisible$ = signal(false);

  protected readonly childDropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 9,
      offsetY: -7,
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: -5,
      offsetY: 6,
    },
  ];
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly elementRef = inject(ElementRef);

  @HostBinding('class.cc-disabled')
  get hasDisabledClass(): boolean {
    return this.disabled;
  }

  @HostBinding('class.cc-active')
  get hasActiveClass(): boolean {
    return this.active;
  }

  @HostBinding('class.cc-focusable')
  get focusable(): boolean {
    if (!this._focusable) return false;
    if (this.disabled) return false;
    if (this.selectable) return true;
    return !this.active;
  }

  @HostBinding('attr.aria-disabled')
  get ariaDisabled(): boolean {
    return this.disabled;
  }

  @HostBinding('attr.aria-expanded')
  get ariaExpanded(): boolean {
    return this.childDropdownVisible$();
  }

  @HostBinding('attr.aria-selected')
  get ariaSelected(): boolean {
    return this.selected;
  }

  @HostBinding('attr.data-active')
  get dataActive(): boolean {
    return this.active;
  }

  @HostBinding('attr.data-selectable')
  get dataSelectable(): boolean {
    return this.selectable;
  }

  @HostBinding('attr.data-selected')
  get dataSelected(): boolean {
    return this.selected;
  }

  @HostBinding('attr.role')
  get role(): string {
    return this.selectable ? 'option' : 'button';
  }

  @HostBinding('attr.tabindex')
  get tabIndex(): number {
    return this.focusable ? 0 : -1;
  }

  handleChildDropdownAttach(): void {
    this.childDropdownVisible$.set(true);
  }

  handleChildDropdownDetach(): void {
    this.childDropdownVisible$.set(false);
    this.requestFocus.emit(this.elementRef.nativeElement);
  }

  @HostListener('click', ['$event'])
  protected handleClick(event: MouseEvent): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return;
    }
    if (this.childDropdown) this.childDropdownVisible$.update((visible) => !visible);
  }
}
