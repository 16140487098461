import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  inject,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CdkConnectedOverlay, ConnectedPosition } from '@angular/cdk/overlay';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from '@design/forms/select/select-item/select-item';
import { SelectItemComponent } from '@design/forms/select/select-item/select-item.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, tap } from 'rxjs';
import { NgTemplateOutlet } from '@angular/common';
import { startWith } from 'rxjs/operators';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ResizeDirective, ResizeEvent } from '@core/directives/resize.directive';
import { SelectPreviewComponent } from './select-preview/select-preview.component';

@UntilDestroy()
@Component({
  selector: 'cc-select',
  standalone: true,
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    CdkConnectedOverlay,
    DropdownComponent,
    TooltipDirective,
    ResizeDirective,
    SelectPreviewComponent,
  ],
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  mode: 'single' | 'multiple' = 'single';

  @Input()
  required = false;

  @Input()
  placeholder = '';

  @Input()
  tooltip: string | TemplateRef<unknown>;

  @Input()
  errorMessage: string | undefined;

  @Input()
  size: 'default' | 'large' = 'default';

  @Input()
  appearance: 'default' | 'ghost' = 'default';

  @Input()
  allowEmpty = false; // Only for single mode

  @Input()
  autoClose = true;

  @Input()
  maxHeight = '300px';

  @Input()
  minWidth = 240;

  @ContentChildren(SelectItemComponent)
  items: QueryList<SelectItemComponent>;

  @ViewChild('select', { read: ElementRef })
  select: ElementRef<HTMLDivElement>;

  protected activeItems: SelectItem[] = [];

  protected selectDropdownOpen = false;
  protected selectDropdownWidth: string | undefined = undefined;

  protected readonly selectDropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 4,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -4,
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 4,
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetY: -4,
    },
  ];
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private itemsListeners: Subscription[] = [];

  private readonly cdr = inject(ChangeDetectorRef);

  protected _disabled = false;

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    if (disabled) this.selectDropdownOpen = false;
  }

  get defaultPreviewString(): string {
    return this.activeItems.map((i) => i.title).join(', ');
  }

  writeValue(value: SelectItem | SelectItem[] | undefined): void {
    if (Array.isArray(value)) {
      this.activeItems = value;
    } else {
      this.activeItems = value ? [value] : [];
    }

    this.updateItemsProps();
  }

  registerOnChange(fn: unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: unknown): void {
    this.onTouch = fn;
  }

  ngAfterViewInit(): void {
    this.items.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        tap(() => {
          this.updateItemsProps();
          this.listenForItemsSelection();
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  toggleSelect(): void {
    if (this.disabled) return;
    this.selectDropdownOpen = !this.selectDropdownOpen;
  }

  focus(): void {
    setTimeout(() => {
      this.select.nativeElement.focus();
    }, 0);
  }

  handleSelectResize(event: ResizeEvent) {
    const {
      newRect: { width },
    } = event;
    this.selectDropdownWidth = CSS.px(Math.max(width, this.minWidth)).toString();
  }

  private updateItemsProps(): void {
    if (!this.items) return;

    this.items.forEach((item) => {
      item._mode = 'single';
      item.selected = this.activeItems.some((activeItem) => activeItem.id === item.instance.id);
      item.update();
    });

    this.cdr.detectChanges();
  }

  private listenForItemsSelection(): void {
    this.itemsListeners.forEach((listener) => listener.unsubscribe());

    this.itemsListeners = this.items.map((item) => {
      return item._toggle
        .pipe(
          untilDestroyed(this),
          tap(() => {
            const selected = item.selected;
            const mutated = selected ? this.deselectItem(item.instance) : this.selectItem(item.instance);

            if (mutated && this.autoClose) this.selectDropdownOpen = false;
          }),
          tap(() => {
            this.cdr.detectChanges();
          }),
        )
        .subscribe();
    });
  }

  private deselectItem(item: SelectItem): boolean {
    if (this.mode === 'single' && !this.allowEmpty) return false;

    this.activeItems = this.activeItems.filter((activeItem) => activeItem.id !== item.id);

    this.handleValueChange();
    return true;
  }

  private selectItem(item: SelectItem): boolean {
    const alreadyInList = this.activeItems.some((activeItem) => activeItem.id === item.id);
    if (alreadyInList) return false;

    if (this.mode === 'single') {
      this.activeItems = [item];
    } else {
      this.activeItems.push(item);
    }

    this.handleValueChange();
    return true;
  }

  private handleValueChange(): void {
    const items = this.activeItems;

    switch (this.mode) {
      case 'single':
        this.onChange(items[0]);
        this.onTouch(items[0]);
        break;
      case 'multiple':
        this.onChange(items);
        this.onTouch(items);
        break;
    }

    this.updateItemsProps();
  }

  private onChange: any = () => {};

  private onTouch: any = () => {};
}
