import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { SelectItem } from '@design/forms/select/select-item/select-item';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';

@Component({
  selector: 'cc-select-item',
  standalone: true,
  imports: [DropdownActionComponent],
  templateUrl: './select-item.component.html',
  styleUrl: './select-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectItemComponent {
  @Input({ required: true })
  id: string;

  @Input({ required: true })
  value: string;

  @Input()
  disabled = false;

  @Input()
  payload: unknown;

  @Output()
  _toggle = new EventEmitter<void>();

  selected = false;
  _mode: 'single' | 'multiple' = 'single';

  private readonly cdr = inject(ChangeDetectorRef);

  get instance(): SelectItem {
    return {
      id: this.id,
      title: this.value,
      payload: this.payload,
    };
  }

  toggle(): void {
    if (this.disabled) return;
    this._toggle.emit();
  }

  update(): void {
    this.cdr.detectChanges();
  }
}
